import React from "react";
import RedHeart from "../../../assets/icons/red-heart.svg";

const FooterBar = (props) => {
  return (
    <div className="footer-bar">
      <p className="text-white">
        Made with{" "}
        <img className="footer-red-heart" src={RedHeart} alt="red-heart"></img>{" "}
        in India
      </p>
    </div>
  );
};

export default FooterBar;
